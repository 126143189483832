// src/utils/websocket.js
let socket = null;
const typeListeners = {}; // 按消息类型管理的监听器
let heartbeatInterval = null;
let reconnectTimeout = null; // 用于自动重连的定时器
let reconnectAttempts = 0;   // 当前重连尝试次数
const maxReconnectAttempts = 10; // 最大重连尝试次数
let isConnecting = false;    // 防止同时多次调用 connect

const WebSocketManager = {
    connect(url) {
        if (isConnecting) {
            console.warn('WebSocket is already connecting...');
            return;
        }

        // 如果已经存在连接，先断开
        if (socket) {
            console.warn('WebSocket is already connected. Disconnecting...');
            this.disconnect();
        }

        isConnecting = true;
        let wsUrl;
        if (localStorage.getItem("token")) {
            wsUrl = url + "?token=" + localStorage.getItem("token");
        } else {
            wsUrl = url;
        }

        console.log('Connecting to WebSocket:', wsUrl);
        socket = new WebSocket(wsUrl);

        socket.onopen = () => {
            console.log('WebSocket connection established.');
            isConnecting = false;
            this.startHeartbeat();
            this.clearReconnect(); // 清除重连状态
        };

        socket.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data); // 假设服务端发来的数据是 JSON 格式
                const type = data.type;
                if (type && typeListeners[type]) {
                    typeListeners[type].forEach((callback) => callback(data));
                }
            } catch (error) {
                console.error('Failed to parse WebSocket message:', error);
            }
        };

        socket.onclose = () => {
            console.log('WebSocket connection closed.');
            isConnecting = false;
            this.stopHeartbeat();
            socket = null;
            this.tryReconnect(url); // 尝试重连
        };

        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
            isConnecting = false;
            this.tryReconnect(url); // 尝试重连
        };
    },

    disconnect() {
        if (socket) {
            console.log('Closing existing WebSocket connection...');
            socket.close();
            socket = null;
        }
        this.stopHeartbeat();
        this.clearReconnect(); // 停止自动重连
        isConnecting = false; // 重置连接状态
    },

    startHeartbeat() {
        if (heartbeatInterval) {
            console.warn('Heartbeat is already running.');
            return;
        }
        heartbeatInterval = setInterval(() => {
            if (socket && socket.readyState === WebSocket.OPEN) {
                console.log('Sending ping...');
                socket.send(JSON.stringify({ type: 2 }));
            }
        }, 30000); // 每30秒发送一次
    },

    sendMessage(message) {
        if (socket && socket.readyState === WebSocket.OPEN) {
            if (typeof message === 'object') {
                message = JSON.stringify(message); // 如果是对象，转换为字符串
            }
            socket.send(message);
            console.log('Message sent:', message);
        } else {
            console.error('WebSocket is not connected or not ready.');
        }
    },

    stopHeartbeat() {
        if (heartbeatInterval) {
            clearInterval(heartbeatInterval);
            heartbeatInterval = null;
        }
    },

    tryReconnect(url) {
        if (reconnectAttempts >= maxReconnectAttempts) {
            console.error('Max reconnect attempts reached. Giving up.');
            return;
        }

        if (!reconnectTimeout) {
            reconnectAttempts++;
            const delay = Math.min(1000 * reconnectAttempts, 10000); // 重连间隔递增，最大10秒
            console.log(`Attempting to reconnect (#${reconnectAttempts}) in ${delay / 1000} seconds...`);

            reconnectTimeout = setTimeout(() => {
                reconnectTimeout = null;
                this.connect(url);
                this.restoreListeners(); // 重连后恢复监听器
            }, delay);
        }
    },

    clearReconnect() {
        if (reconnectTimeout) {
            clearTimeout(reconnectTimeout);
            reconnectTimeout = null;
        }
        reconnectAttempts = 0; // 重置重连计数
    },

    // 添加特定 type 的监听器
    addTypeListener(type, callback) {
        if (!typeListeners[type]) {
            typeListeners[type] = [];
        }
        // 清除该类型下所有旧的监听器
        typeListeners[type] = [callback];
        console.log(`Listener for type "${type}" has been replaced.`);
    },

    // 移除特定 type 的监听器
    removeTypeListener(type, callback) {
        if (typeListeners[type]) {
            const index = typeListeners[type].indexOf(callback);
            if (index !== -1) {
                typeListeners[type].splice(index, 1);
            }
        }
    },

    // 恢复所有监听器
    restoreListeners() {
        console.log('Restoring listeners after reconnect...');
        let totalListeners = 0;

        Object.keys(typeListeners).forEach((type) => {
            const listeners = typeListeners[type];
            totalListeners += listeners.length;

            listeners.forEach((callback) => {
                this.addTypeListener(type, callback); // 调用改进后的 addTypeListener
            });
        });

        console.log(`Total unique listeners: ${totalListeners}`);
    },

    clearAllListeners() {
        Object.keys(typeListeners).forEach((type) => {
            typeListeners[type] = [];
        });
        console.log('All listeners cleared.');
    }
    

};

export default WebSocketManager;
