import Vue from 'vue'
import App from './App.vue'
import router from './router/index';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VClipboard from 'v-clipboard';
import WebSocketManager from './utils/websocket';
import {wsURL} from "@/utils/index";
// 初始化 WebSocket
WebSocketManager.connect(wsURL);
Vue.use(ElementUI);
Vue.use(VClipboard);

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
