<template>
  <div class="container">
    <div :class="['aside', { collapse: isCollapse }]">
      <div class="aside-header">
        <i @click="changeCollapse" class="el-icon-s-fold"></i>
      </div>
      <el-menu :default-active="defaultActive" :collapse-transition="false" class="el-menu-vertical-demo"
        @select="handleSelect" :collapse="isCollapse">
        <el-submenu index="1">
          <template slot="title">
            <i class="el-icon-bangzhu"></i>
            <span>icutool工具箱</span>
          </template>
          <el-menu-item index="1-1"><i class="el-icon-takeaway-box"></i> 消息记录</el-menu-item>
          <el-menu-item index="1-2"><i class="el-icon-takeaway-box"></i> 周报管理</el-menu-item>
        </el-submenu>
      </el-menu>
      <div class="image-container" v-if="!isCollapse">
        <ProfileCard :avatarUrl="userInfo.avatarUrl" :nickname="userInfo.username" :geo="userInfo.geo" />
      </div>
      <div class="logout-button">
        <el-button :style="{ fontSize: isCollapse ? '8px' : '14px' }" type="info" @click="logout" block>登出</el-button>
      </div>
    </div>
    <div class="main">
      <div class="main-content" v-if="refresh">
        <router-view></router-view>
      </div>
    </div>
    <!-- 底部导航栏 -->
    <div class="bottom-nav" v-if="isMobile">
      <el-menu :default-active="defaultActive" class="el-menu-horizontal-demo" @select="handleSelect">
        <el-menu-item index="1-1"><i class="el-icon-takeaway-box"></i> 消息记录</el-menu-item>
        <el-menu-item index="1-2"><i class="el-icon-takeaway-box"></i> 周报管理</el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
import avatar from '@/assets/avatar.png';
import ProfileCard from '@/views/account/ProfileCard.vue';
import { loginOut } from "@/api/login";
import { info } from "@/api/user";
import WebSocketManager from '@/utils/websocket';
export default {
  components: {
    ProfileCard,
  },
  data() {
    return {
      isCollapse: false,
      defaultActive: "1-1",
      userInfo: {
        avatarUrl: avatar,
        username: "icutool",
        geo: "",
      },
      menuList: [
        {
          index: "1-1",
          path: "/MessageBroker",
          name: "消息记录",
        },
        {
          index: "1-2",
          path: "/WeeklyReport",
          name: "周报管理",
        }
      ],
      refresh: true,
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth <= 768;
    }
  },
  created() {
    if (window.localStorage.token == null) {
      this.$router.push('/login')
    }
    // 根据当前路由路径设置默认激活的菜单项
    const currentPath = this.$route.path;
    const activeItem = this.menuList.find(item => item.path === currentPath);
    this.defaultActive = activeItem ? activeItem.index : "1-1";

    //获取用户名
    info().then(res => {
      if (res.code == 200) {
        // this.$message.success("获取用户身份成功")
        this.userInfo.username = res.data.nickName
        this.userInfo.geo = res.data.geo
        if (res.data.avatar) {
          this.userInfo.avatarUrl = res.data.avatar
        }
        // this.refresh = false
        // this.$nextTick(() => {
        //   this.refresh = true
        // })
      } else {
        console.log("获取用户身份出错了");
        console.log(res.msg);
        this.$message.error(res.msg)
      }
    }).catch(error => {
      console.log(error.msg || error.message);
    });
  },
  mounted() {
  },
  methods: {
    logout() {
      loginOut().then(res => {
        if (res.code == 200) {
          this.$message.success("登出成功")
          window.localStorage.removeItem('token')
          this.$router.push("/login")
          console.log("清理所有监听器~")
          WebSocketManager.clearAllListeners(); // 清理所有监听器
          // 发送ws注销
          WebSocketManager.sendMessage({ type: 0 })
        } else {
          console.log("出错了");
          console.log(res.msg);
          this.$message.error(res.msg)
        }
      }).catch(error => {
        console.log(error.msg || error.message);
      });
    },
    changeCollapse() {
      this.isCollapse = !this.isCollapse
    },
    handleSelect(index) {
      let pathname = window.location.pathname
      for (let i = 0; i < this.menuList.length; i++) {
        let item = this.menuList[i]
        if (item.index == index && item.path != pathname) {
          this.defaultActive = item.index
          this.mainHeader = item.name
          this.$router.push(item.path)
        }
      }
    }
  }
}
</script>

<style>
.container {
  display: flex;
  min-height: 100vh;
  background-color: #e1e7f0;
}

.aside {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  /* Adjust the height of the aside to the window height */
  transition: width 0.3s;
}

.aside.collapse {
  width: 64px;
}

.aside-header {
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  text-align: right;
  background-color: #fff;
}

.aside-header i {
  font-size: 18px;
  cursor: pointer;
}

.el-menu {
  flex-grow: 1;
  /* Allow the menu to take all the available space */
  border-right: 0;
  overflow-y: auto;
  /* Add scroll if content overflows */
}

.logout-button {
  padding-bottom: 5px;
}

.logout-button span {
  text-align: center;
}

.logout-button .el-button {
  width: 100%;
}

.main {
  flex: 1;
  min-width: 0;
}

.main-header {
  height: 30px;
  line-height: 30px;
  padding-left: 16px;
  font-size: 13px;
  background-color: #e9f2fd;
}

.main-content {
  padding: 16px 20px;
}

:deep(.el-menu-item),
:deep(.el-submenu__title) {
  height: 40px;
  line-height: 40px;
  font-size: 13px;
}

:deep(.el-menu-item.is-active) {
  background-color: #ecf5ff;
}

.menu-icon {
  width: 15px;
  margin-right: 4px;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .aside {
    display: none;
  }

  .bottom-nav {
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
  }

  .bottom-nav .el-menu {
    display: flex;
    justify-content: space-around;
    border-top: 1px solid #ebebeb;
  }

  .bottom-nav .el-menu-item {
    flex: 1;
    text-align: center;
    padding: 0;
    line-height: 50px;
  }
}
</style>
