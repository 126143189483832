<template>
    <div class="login-container">
        <div class="login-box">
            <h2>欢迎登录</h2>
            <h6 @click="toggleLoginWithQR" class="qr">{{ qrButtonText }}</h6>
            <div v-if="isQRLogin">
                <div class="qrcode-box">
                    <img src="../assets/qrcode.png" alt="二维码" />
                    <p>请发送 {{ smsCode }} 到微信执行登录</p>
                    <p>有效期: {{ countdown }} 秒</p>
                </div>
            </div>
            <div v-else>
                <form @submit.prevent="handleLogin">
                    <div class="input-group">
                        <label for="username">{{ isPhoneLogin ? '手机号' : '用户名' }}</label>
                        <input :type="isPhoneLogin ? 'tel' : 'text'" id="username" v-model="username" />
                    </div>
                    <div class="input-group" v-if="!isPhoneLogin">
                        <label for="password">密码</label>
                        <input type="password" id="password" v-model="password" />
                    </div>
                    <div class="input-group" v-if="isPhoneLogin">
                        <label for="code">验证码</label>
                        <input type="text" id="code" v-model="code" />
                    </div>
                    <button type="submit" class="login-button">
                        {{ isPhoneLogin ? '验证码登录' : '登录' }}
                    </button>
                    <button type="button" class="switch-button" @click="toggleLoginMode">
                        {{ isPhoneLogin ? '切换至账号密码登录' : '切换至手机号登录' }}
                    </button>
                </form>
            </div>
        </div>
        <footer>
            <p><a href="https://beian.miit.gov.cn/" target="_blank"
                    style="text-decoration: none;color: black;">Copyright © 2019-2024 PB科技 皖ICP备19019230号-3</a></p>
            <p><a href="https://tool.icutool.cn/" target="_blank"
                style="text-decoration: none;color: black;">友联: icutool程序员工具箱</a></p>
        </footer>
    </div>

</template>

<script>
import { getLogin, getSmsLogin, getQRCode, checkLoginStatus } from "@/api/login";
// import { Message } from 'element-ui';
import WebSocketManager from '@/utils/websocket';
export default {
    data() {
        return {
            username: '',
            phone: '',
            password: '',
            code: "",
            isPhoneLogin: false, // 是否是手机号登录模式
            isQRLogin: false,
            qrButtonText: '切换二维码登录',
            smsCode: '',
            countdown: 0,
            countdownInterval: null,
        };
    },
    created() {
        this.loginHandler = (data) => {
            if (data.type == 52) {
                this.$message.success("登陆成功～");
                this.handleloginWithToken(data);
            }
        };
        WebSocketManager.addTypeListener(52, this.loginHandler);
        this.codeHandler = (data) => {
            if (data.type == 51) {
                this.smsCode = data.data.loginCode;
            }
        };
        WebSocketManager.addTypeListener(51, this.codeHandler);
    },
    beforeDestroy() {
        WebSocketManager.removeTypeListener(52, this.loginHandler);
        WebSocketManager.removeTypeListener(51, this.codeHandler);
    },
    methods: {
        handleLogin() {
            // 处理登录逻辑
            if (!this.isPhoneLogin) {
                getLogin({ username: this.username, password: this.password }).then(res => {
                    if (res.code == 200) {
                        this.$message.success("登陆成功")
                        window.localStorage.token = res.data.token
                        WebSocketManager.sendMessage({ type: 3, data: { token: res.data.token } })
                        this.$router.push("/")
                    } else {
                        console.log("出错了");
                        console.log(res.msg);
                        this.$message.error(res.msg)
                    }
                }).catch(error => {
                    console.log(error.msg || error.message);
                });
            } else {
                getSmsLogin({ mobile: this.username, smsCode: this.code }).then(res => {
                    if (res.code == 200) {
                        this.$message.success("登陆成功")
                        window.localStorage.token = res.data.token
                        this.$router.push("/")
                    } else {
                        console.log("出错了");
                        console.log(res.msg);
                        this.$message.error(res.msg)
                    }
                }).catch(error => {
                    console.log(error.msg || error.message);
                });
            }
        },
        toggleLoginMode() {
            // 切换登录模式
            this.isPhoneLogin = !this.isPhoneLogin;
            // 清空输入框内容
            this.username = "";
            this.password = "";
            this.code = "";
        },
        toggleLoginWithQR() {
            this.isQRLogin = !this.isQRLogin;
            this.qrButtonText = this.isQRLogin ? '返回账号登录' : '切换二维码登录';
            if (this.isQRLogin) {
                this.getQRCode();
            } else {
                clearInterval(this.countdownInterval);
                this.countdown = 0;
            }
        },
        getQRCode() {
            WebSocketManager.sendMessage({ type: 1 });
            this.startCountdown();
        },
        //处理ws推过来的登陆成功消息
        handleloginWithToken(data) {
            console.log(data)
            window.localStorage.token = data.data.token;
            this.$message.success("登录成功");
            clearInterval(this.countdownInterval);
            this.$router.push("/");
        },
        startCountdown() {
            this.countdown = 300; // 设置倒计时时间为5分钟（300秒）
            this.countdownInterval = setInterval(() => {
                if (this.countdown > 0) {
                    this.countdown--;
                } else {
                    clearInterval(this.countdownInterval);
                    this.getQRCode(); // 倒计时结束后自动获取新的验证码
                }
            }, 1000);
        },
    },
    beforeDestroy() {
        clearInterval(this.countdownInterval); // 清除计时器
    }
};

</script>

<style>
html,
/* 全局样式 */
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* 防止滚动条 */
}

.login-container {
    display: flex;
    /* 设置flex-direction为column，使容器内的项目垂直堆叠 */
    flex-direction: column;
    /* 设置容器内的项目对齐方式为center */
    align-items: center;
    /*  设置容器内的项目水平对齐方式为center */
    justify-content: center;
    /*  设置容器高度为100vh，即屏幕高度 */
    height: 100vh;
    /*  设置容器的背景色为#f5f5f5 */
    background-color: #f5f5f5;
    /*  设置容器的padding为10px */
    box-sizing: border-box;
    padding: 10px;
    /* 确保内容不会紧贴边缘 */
}

.login-box {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    /* 最大宽度 */
    width: 100%;
    /* 确保在小屏幕上也能适应 */
    box-sizing: border-box;
}


.qrcode-box img {
    /* justify-content: center;
    align-items: center; */
    width: 200px;
    height: 200px;
}

.qrcode-box p {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 15px;
    color: #4CAF50;
    /* 可选：设置文本颜色 */
}

.login-box h2 {
    margin-bottom: 5px;
    /* 减少标题与下面元素之间的间距 */
    margin-top: 5px;
}

.qr {
    margin: 0;
    padding: 5px 0;
    /* 减少二维码登录切换按钮的间距 */
    cursor: pointer;
    /* 增加鼠标悬停时的指针样式 */
    color: #4CAF50;
    /* 可选：设置文本颜色 */
}


.input-group {
    margin-bottom: 15px;
    text-align: left;
}

.input-group label {
    display: block;
    margin-bottom: 5px;
}

.input-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.login-button,
.switch-button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 4px;
    background-color: #4CAF50;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.switch-button {
    background-color: #8BC34A;
}

footer {
    position: fixed;
    /* 使用 fixed 定位 */
    bottom: 0;
    /* 将 footer 粘贴在页面底部 */
    width: 100%;
    /* 确保 footer 宽度与页面一致 */
    background-color: #f5f5f5;
    /* 设置背景色 */
    padding: 10px;
    /* 可选：设置 padding 以增加空白 */
}
</style>